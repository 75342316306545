<template>
    <div class="padding-container">
        <template v-if="page_content == 'main_page'">
            <div class="fw-bold">
                <div
                    @click="$router.go(-1)"
                    class="text-gray-dashboard size-16 cursor-pointer"
                >
                    <i class="fa fa-angle-left me-2"></i>
                    Back
                </div>
            </div>
            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold">
                        Detail Customers Prospect and Leads Data
                    </h3>
                    <div class="ms-auto">
                        <a
                            :href="
                                '/crm/customers/leads/form/' +
                                this.$route.params.id
                            "
                        >
                            <i class="fa fa-pencil me-1"></i>
                            Edit
                        </a>
                    </div>
                </div>
                <div class="mt-4">
                    <label class="fw-bold">Tags</label>
                    <div class="mt-2">
                        <template v-if="detailLeads.tags != null">
                            <template v-for="(tag, index) in detailLeads.tags">
                                <span
                                    class="tag gray radius pe-3 ps-3"
                                    :key="index"
                                    >{{ tag }}</span
                                >
                            </template>
                        </template>
                        <template v-else> - </template>
                    </div>
                </div>
                <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                    <tr class="">
                        <td class="ps-0 w-200">Full Name</td>
                        <td class="w-30">:</td>
                        <td>
                            {{ detailLeads?.name ? detailLeads?.name : "-" }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Phone Number</td>
                        <td class="w-30">:</td>
                        <td>
                            {{ detailLeads?.phone ? detailLeads?.phone : "-" }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Email</td>
                        <td class="w-30">:</td>
                        <td>
                            {{ detailLeads?.email ? detailLeads?.email : "-" }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Company</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                detailLeads?.company
                                    ? detailLeads?.company
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Position</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                detailLeads?.position
                                    ? detailLeads?.position
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Office Number</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                detailLeads?.office_number
                                    ? detailLeads?.office_number
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Address</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                detailLeads?.address
                                    ? detailLeads?.address
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Source Data</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                detailLeads?.source ? detailLeads?.source : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Data Type</td>
                        <td class="w-30">:</td>
                        <td>
                            {{ detailLeads?.type ? detailLeads?.type : "-" }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Chat</td>
                        <td class="w-30">:</td>
                        <td>
                            <template v-if="detailLeads.has_chat == true">
                                <a
                                    href="javascript:void(0)"
                                    @click="openModalChatQontak()"
                                    class=""
                                >
                                    <u>
                                        View Chat Qontak
                                        <i class="fa fa-external-link ms-1"></i>
                                    </u>
                                </a>
                            </template>
                            <template v-else> - </template>
                        </td>
                    </tr>
                </table>
                <hr class="mt-1" />
                <div class="row">
                    <div class="col-md-2 mt-2">
                        <a
                            :href="
                                '/crm/customers/leads/prospect/' +
                                this.$route.params.id
                            "
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/prospect.png"
                                width="19"
                                class="me-1"
                                alt=""
                            />
                            Follow Up
                        </a>
                    </div>
                    <div class="col-md-2 mt-2">
                        <a
                            :href="
                                '/crm/customers/leads/prospect/' +
                                this.$route.params.id +
                                '?type=mail'
                            "
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/mail.png"
                                width="19"
                                class="me-1"
                                alt=""
                            />
                            Send Mail
                        </a>
                    </div>
                    <div class="col-md-2 mt-2">
                        <a
                            href="javascript:void(0)"
                            @click="clickNotes()"
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/notes.png"
                                width="19"
                                class="me-1"
                                alt=""
                            />
                            Notes
                        </a>
                    </div>
                    <div class="col-md-3 mt-2">
                        <a
                            :href="
                                '/crm/customers/leads/prospect/' +
                                this.$route.params.id +
                                '?type=meeting'
                            "
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/meeting.png"
                                width="19"
                                class="me-1"
                                alt=""
                            />
                            Google Meets
                        </a>
                    </div>
                    <div class="col-md-2 mt-2">
                        <button
                            @click="clickWA()"
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/wa.png"
                                width="20"
                                class="me-1"
                                alt=""
                            />
                            WhatsApp
                        </button>
                    </div>
                </div>
            </div>
            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3 me-auto">Predictions History</h3>
                </div>

                <div class="mt-4" v-if="is_prediction_history">
                    <ListLoader />
                </div>
                <div v-else>
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Date
                                    </th>
                                    <th class="text-center" scope="col">
                                        Services
                                    </th>
                                    <th class="text-center" scope="col">
                                        Route
                                    </th>
                                    <th class="text-center" scope="col">
                                        Price
                                    </th>
                                    <th class="text-center" scope="col">
                                        Note
                                    </th>
                                    <th class="text-center" scope="col">
                                        Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in dataPrediction"
                                    :key="index"
                                >
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center">
                                        {{
                                            moment(item.date).format(
                                                "DD/MM/YYYY"
                                            )
                                        }}
                                    </td>
                                    <td class="text-center capitalize">
                                        <template
                                            v-if="
                                                item.product ==
                                                    'trawlpack-goods' ||
                                                item.product == 'trawlpack-bike'
                                            "
                                        >
                                            TroPack
                                        </template>
                                        <template v-else>
                                            {{ item.product }}
                                        </template>
                                    </td>
                                    <td>
                                        {{ item.origin_regency }} -
                                        {{ item.destination_regency }}
                                    </td>
                                    <td class="text-center">
                                        {{ currencyFormat(item.amount) }}
                                    </td>
                                    <td class="text-center capitalize">
                                        <template
                                            v-if="item.product == 'trawltruck'"
                                        >
                                            {{
                                                (item.fleet_type
                                                    ? item.fleet_type
                                                    : "-"
                                                )
                                                    .split("_")
                                                    .join(" ")
                                            }}
                                        </template>
                                        <template
                                            v-else-if="
                                                item.product == 'trawlcarrier'
                                            "
                                        >
                                            {{
                                                (item.service_type
                                                    ? item.service_type
                                                    : "-"
                                                )
                                                    .split("_")
                                                    .join(" ")
                                            }}
                                        </template>
                                        <template v-else>
                                            {{
                                                item.weight ? item.weight : "-"
                                            }}
                                            Kg
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        {{
                                            item.description
                                                ? item.description
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        v-if="dataPrediction.length == 0"
                        class="text-center mt-section"
                    >
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>
                <div class="overflow-auto mt-3">
                    <b-pagination-nav
                        v-if="dataPrediction.length > 0"
                        v-model="currentPagePrediction"
                        :number-of-pages="totalPagePrediction"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePagePredictionHistory"
                    ></b-pagination-nav>
                </div>
            </div>
            <div class="box my-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3 me-auto">Activities History</h3>
                </div>

                <ul class="nav nav-pills mt-4" id="pills-tab" role="tablist">
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard left w-100 active"
                            id="pills-prospect-tab"
                            @click="getResult('prospect')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-prospect"
                            type="button"
                            role="tab"
                            aria-controls="pills-prospect"
                            aria-selected="true"
                        >
                            Leads
                        </button>
                    </li>
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard center w-100"
                            id="pills-email-tab"
                            @click="getResult('mail')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-email"
                            type="button"
                            role="tab"
                            aria-controls="pills-email"
                            aria-selected="false"
                        >
                            Email
                        </button>
                    </li>
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard center w-100"
                            id="pills-notes-tab"
                            @click="getResult('notes')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-notes"
                            type="button"
                            role="tab"
                            aria-controls="pills-notes"
                            aria-selected="false"
                        >
                            Notes
                        </button>
                    </li>
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard center w-100"
                            id="pills-meeting-tab"
                            @click="getResult('meeting')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-meeting"
                            type="button"
                            role="tab"
                            aria-controls="pills-meeting"
                            aria-selected="false"
                        >
                            Meeting
                        </button>
                    </li>
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard right w-100"
                            id="pills-whatsapp-tab"
                            @click="getResult('whatsapp')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-whatsapp"
                            type="button"
                            role="tab"
                            aria-controls="pills-whatsapp"
                            aria-selected="false"
                        >
                            WhatsApp
                        </button>
                    </li>
                </ul>

                <div class="tab-content" id="pills-tabContent">
                    <div
                        class="tab-pane fade show active mt-3"
                        id="pills-prospect"
                        role="tabpanel"
                        aria-labelledby="pills-prospect-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Activities
                                            </th>
                                            <th class="text-center" scope="col">
                                                Owner
                                            </th>
                                            <th class="text-center" scope="col">
                                                Description
                                            </th>
                                            <th class="text-center" scope="col">
                                                Result
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item?.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center capitalize">
                                                {{
                                                    (item?.activity
                                                        ? item?.activity
                                                        : "-"
                                                    )
                                                        .split("_")
                                                        .join(" ")
                                                }}
                                            </td>
                                            <td class="text-center capitalize">
                                                {{
                                                    item?.sender
                                                        ? item?.sender
                                                        : "-"
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    item?.description
                                                        ? item?.description
                                                        : "-"
                                                }}
                                            </td>
                                            <td class="text-center capitalize">
                                                <span
                                                    class="tag w-100 radius"
                                                    v-bind:class="{
                                                        'green-solid':
                                                            item?.result ==
                                                            'deal',
                                                        'yellow-solid':
                                                            item?.result ==
                                                            'pending',
                                                        'red-solid':
                                                            item?.result ==
                                                            'lost',
                                                    }"
                                                >
                                                    {{
                                                        item?.result
                                                            ? item?.result
                                                            : "-"
                                                    }}
                                                </span>
                                                <p
                                                    v-if="
                                                        item?.result == 'deal'
                                                    "
                                                >
                                                    {{ item?.result_info }}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePageCustomerOrdered()"
                            ></b-pagination-nav>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade mt-3"
                        id="pills-email"
                        role="tabpanel"
                        aria-labelledby="pills-email-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                From
                                            </th>
                                            <th class="text-center" scope="col">
                                                To
                                            </th>
                                            <th class="text-center" scope="col">
                                                Cc
                                            </th>
                                            <th class="text-center" scope="col">
                                                Subject
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    item?.from
                                                        ? item?.from
                                                        : "-"
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{ item?.to ? item?.to : "-" }}
                                            </td>
                                            <td class="text-center">
                                                <template
                                                    v-if="item.cc != null"
                                                >
                                                    <template
                                                        v-for="(
                                                            cc, index
                                                        ) in item.cc"
                                                    >
                                                        <span :key="index">
                                                            {{ cc }}
                                                        </span>
                                                    </template>
                                                </template>
                                                <template v-else> - </template>
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    item?.subject
                                                        ? item?.subject
                                                        : "-"
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePage('mail')"
                            ></b-pagination-nav>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade mt-3"
                        id="pills-notes"
                        aria-labelledby="pills-notes-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Notes
                                            </th>
                                            <th class="text-center" scope="col">
                                                Files
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.note }}
                                            </td>
                                            <td class="text-center">
                                                <template
                                                    v-if="
                                                        item.attachment == null
                                                    "
                                                >
                                                    -
                                                </template>
                                                <template v-else>
                                                    <a
                                                        :href="
                                                            item.attachment_url
                                                        "
                                                        target="_blank"
                                                    >
                                                        <i
                                                            class="fa fa-paperclip me-1"
                                                        ></i>
                                                        See File
                                                    </a>
                                                </template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePageCustomerOrdered()"
                            ></b-pagination-nav>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade mt-3"
                        id="pills-meeting"
                        aria-labelledby="pills-meeting-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Time
                                            </th>
                                            <th class="text-center" scope="col">
                                                Meeting Type
                                            </th>
                                            <th class="text-center" scope="col">
                                                Description
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.meeting_time }}
                                            </td>
                                            <td class="text-center capitalize">
                                                {{ item.type }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.description }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePageCustomerOrdered()"
                            ></b-pagination-nav>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade mt-3"
                        id="pills-whatsapp"
                        aria-labelledby="pills-whatsapp-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Time
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "HH:mm"
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePageCustomerOrdered()"
                            ></b-pagination-nav>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="page_content == 'notes'">
            <div class="fw-bold">
                <a
                    href="javascript:void(0)"
                    @click="page_content = 'main_page'"
                    class="text-gray-dashboard size-16"
                >
                    <i class="fa fa-angle-left me-2"></i>
                    Back
                </a>
            </div>
            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3">Add New Notes</h3>
                </div>
                <div class="mt-3">
                    <label class="fw-bold mb-2">Notes</label>
                    <textarea
                        rows="7"
                        cols="30"
                        class="form-control"
                        placeholder="Type Here"
                        v-model="req.notes"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <label class="fw-bold">Document (Optional)</label>
                    <div class="text-gray mb-2">Document max 5MB</div>
                    <label
                        type="file"
                        :class="!attachment ? 'upload__btn' : ''"
                    >
                        <img
                            v-if="attachment"
                            class="photo"
                            :src="attachmentPictureUrl"
                        />
                        <p v-else class="mb-0">
                            <i class="fa fa-plus"></i>
                        </p>
                        <input
                            class="cursor-pointer"
                            id="upload"
                            type="file"
                            name="file"
                            ref="file"
                            @change="uploadPhoto"
                        />
                    </label>
                </div>
                <div class="mt-4">
                    <button
                        class="btn btn-green btn-lg px-5"
                        type="button"
                        disabled
                        v-if="is_save_notes"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Save
                    </button>
                    <button
                        type="submit"
                        v-else
                        class="btn btn-green btn-lg px-5"
                        @click="saveNotes()"
                        :disabled="req.notes == ''"
                    >
                        Save
                    </button>
                </div>
            </div>

            <div class="modal-vue">
                <div class="overlay" v-if="modalSuccessNotes"></div>
                <div class="modal-body-chat vsm" v-if="modalSuccessNotes">
                    <div class="mt-4 text-center">
                        <img
                            src="../../assets/dashboard/checklis.png"
                            width="80"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4">
                            Create Notes Succeeded!
                        </h4>
                    </div>
                    <div class="mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="successNotes()"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <div class="modal-vue">
            <div class="overlay" v-if="modalChatQontak"></div>
            <div class="modal-body-chat md" v-if="modalChatQontak">
                <div class="d-flex align-items-center">
                    <h4 class="mb-0 fw-bold">View Chat Qontak</h4>
                    <div class="ms-auto">
                        <a
                            href="javascript:void(0)"
                            @click="modalChatQontak = false"
                            class="text-danger"
                        >
                            <i class="fa fa-times-circle size-20"></i>
                        </a>
                    </div>
                </div>
                <div class="chat-body mb-2">
                    <div
                        class="modal-content"
                        :style="{
                            backgroundImage: `url(${require('../../assets/bc-chat.png')})`,
                        }"
                    >
                        <div class="modal-body">
                            <div
                                class="msg-body"
                                v-bind:class="{
                                    scroll: chats.length > 3,
                                }"
                            >
                                <ul>
                                    <li
                                        v-for="(chat, index) in chats"
                                        v-bind:class="{
                                            replay:
                                                chat.type == 'agent' ||
                                                chat.type == 'bot',
                                            sender: chat.type == 'customer',
                                        }"
                                        :key="index"
                                    >
                                        <div v-if="todayFilter">
                                            <center>
                                                <span
                                                    class="today-cip"
                                                    v-if="
                                                        chat.id ==
                                                        todayFilter.id
                                                    "
                                                    >Today</span
                                                >
                                            </center>
                                        </div>
                                        <div
                                            class="d-flex"
                                            v-bind:class="{
                                                'justify-content-end':
                                                    chat.type == 'agent' ||
                                                    chat.type == 'bot',
                                            }"
                                        >
                                            <div
                                                class="avatar-chat me-2"
                                                v-if="chat.type == 'customer'"
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        chat.avatar +
                                                        ')',
                                                }"
                                            ></div>
                                            <div>
                                                <div class="fw-bold mb-1">
                                                    {{ chat.name }}
                                                </div>
                                                <div class="content-msg">
                                                    <div
                                                        v-if="
                                                            chat.message_reply
                                                        "
                                                        class="p-box-chat mb-1 radius-small text-gray-dashboard size-14"
                                                    >
                                                        {{ chat.message_reply }}
                                                    </div>
                                                    {{ chat.message }}
                                                </div>
                                                <span class="time">
                                                    {{ chat.timestamp_parse }}
                                                </span>
                                            </div>
                                            <div
                                                class="avatar-chat ms-2"
                                                v-if="
                                                    chat.type == 'agent' ||
                                                    chat.type == 'bot'
                                                "
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        chat.avatar +
                                                        ')',
                                                }"
                                            ></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import noImage from "../../assets/no-photo.png";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import $ from "jquery";

export default {
    name: "customersLeadsDetail",
    components: {
        ListLoader,
    },
    data() {
        return {
            moment: moment,
            detailLeads: {},
            page_content: "main_page",
            item_id: null,
            is_save_notes: false,
            modalSuccessNotes: false,
            req: {
                notes: "",
                attachment: "",
                search_prediction_history: "",
            },
            attachment: "",
            is_prediction_history: false,
            limitPrediction: 10,
            totalPagePrediction: 0,
            currentPagePrediction: 0,
            dataPrediction: [],
            load_activities_history: false,
            currentPageActivitiesHistory: 0,
            totalPageActivitiesHistory: 0,
            limitActivitiesHistory: 10,
            activities_history: [],
            typeResult: "prospect",
            modalChatQontak: false,
            chats: [],
            maleItemDisplayed: false,
        };
    },
    created() {
        this.getDetailLeads();
        this.getPredictionHistory();
        this.getActivitiesHistory();
        this.getChats();
    },
    computed: {
        attachmentPictureUrl() {
            return this.attachment != "" ? this.attachment : noImage;
        },
        todayFilter() {
            return this.chats.find(
                (item) =>
                    item.timestamp_parse ==
                    moment(item.timestamp).format(`HH:mm`)
            );
        },
    },
    methods: {
        // isdiff(v1, v2) {
        //     return bool true / false
        // },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        clickNotes() {
            this.page_content = "notes";
            this.item_id = this.$route.params.id;
        },
        successNotes() {
            window.location.href =
                "/crm/customers/leads/detail/" + this.$route.params.id;
        },
        clickWA() {
            window.open("https://wa.me/6281399000000", "_blank");
            window.location.href =
                "/crm/customers/leads/prospect/" +
                this.$route.params.id +
                "?type=whatsapp";
        },
        getDetailLeads() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/lead/detail`,
                {
                    params: { id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detailLeads = res.data?.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getPredictionHistory() {
            this.is_prediction_history = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/order/predictions`,
                {
                    params: {
                        customer_id: this.$route.params.id,
                        type: "lead",
                        product: "trawlpack",
                        page:
                            this.currentPagePrediction == 0 ||
                            this.currentPagePrediction == null
                                ? 0
                                : this.currentPagePrediction - 0,
                        limit: this.limitPrediction,
                        search: this.req.search_prediction_history,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataPrediction = res.data.data.list_data;
                    this.totalPagePrediction = res.data.data.total_page;
                    this.is_prediction_history = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_prediction_history = false;
                });
        },
        getActivitiesHistory() {
            this.load_activities_history = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/order/activities`,
                {
                    params: {
                        customer_id: this.$route.params.id,
                        type: "lead",
                        tab: this.typeResult,
                        page:
                            this.currentPageActivitiesHistory == 0 ||
                            this.currentPageActivitiesHistory == null
                                ? 0
                                : this.currentPageActivitiesHistory - 1,
                        limit: this.limitActivitiesHistory,
                        search: this.search_activities_history,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.activities_history = res.data.data?.list_data;
                    this.totalPageActivitiesHistory = res.data.data?.total_page;
                    this.load_activities_history = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.load_activities_history = false;
                });
        },
        changePagePredictionHistory() {
            this.getPredictionHistory();
        },
        changePageCustomerOrdered() {
            this.getActivitiesHistory();
        },
        getResult(value) {
            this.typeResult = value;
            this.getActivitiesHistory();
        },
        saveNotes() {
            this.is_save_notes = true;
            var data = new FormData();

            data.append("customer_id", this.item_id);
            data.append("type", "lead");
            data.append("content", this.req.notes);
            data.append("attachment", this.req.attachment);
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/note/create`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_save_notes = false;
                    this.modalSuccessNotes = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_save_notes = false;
                });
        },
        uploadPhoto(event) {
            var input = event.target;
            this.req.attachment = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.attachment = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        openModalChatQontak() {
            this.modalChatQontak = true;
            setTimeout(() => {
                var ChatDiv = $(".scroll");
                var height = ChatDiv[0].scrollHeight;
                ChatDiv.scrollTop(height);
            }, 10);
        },
        getChats() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/leads/chat`,
                {
                    params: { id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.chats = data;
                })
                .catch((err) => {
                    console.log(err);
                    // this.$notify({
                    //     group: "foo",
                    //     type: "error",
                    //     title: "Error",
                    //     text: err.response.data.message,
                    // });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px;
}
tr {
    border-color: #e8e8e9;
    padding: 10px;
}
td {
    padding: 10px;
}
.w-200 {
    width: 200px;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.center {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
.w-33 {
    width: 33% !important;
}
.w-20 {
    width: 20% !important;
}
.w-34 {
    width: 34% !important;
}
.border-top {
    border-color: #eee !important;
}
.scroll-chat {
    height: 400px;
    width: 100%;
    overflow: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.msg-body.scroll {
    height: 380px;
    overflow-y: scroll;
}
.msg-body ul {
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.msg-body ul li {
    list-style: none;
    margin: 0px 0;
}

.msg-body ul li.sender {
    display: block;
    width: 100%;
    position: relative;
}
.msg-body ul li.sender .content-msg {
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 8px;
    background: #fff;
    display: inline-block;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 0;
}

.msg-body ul li.sender .content-msg b {
    display: block;
    color: #180660;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.replay {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
}
.msg-body ul li.replay .content-msg {
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 8px;
    background: #fff;
    display: inline-block;
    text-align: left;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-bottom: 0;
}

.msg-body ul li.replay .content-msg b {
    display: block;
    color: #061061;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.replay:after {
    display: block;
    content: "";
    clear: both;
}

.time {
    display: block;
    color: #8a92a6;
    font-size: 11px;
    line-height: 2;
    font-weight: 400;
    text-align: right;
}
.chat-body {
    background: rgb(239, 234, 226);
    border-radius: 10px;
    margin-top: 20px;
}
.avatar-chat {
    width: 35px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.p-box-chat {
    padding: 6px 8px;
    border-radius: 5px;
    background: #f5f5f5;
}
.text-left {
    text-align: left;
}
.today-cip {
    background: #f5f5f5;
    color: #61616a;
    border-radius: 5px;
    padding: 2px 9px 3px 9px;
}
</style>
