<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-4">List Data Order Truk</h3>
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-lg-5 my-2">
                            <div class="position-relative">
                                <input
                                    type="text"
                                    class="form-control form-control-lg pl-search"
                                    placeholder="Cari nomor resi atau nama pengirim"
                                    v-model="search"
                                    @keyup.enter="(currentPage = 1), getLists()"
                                />
                                <span class="search-icon left">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4 my-2">
                            <router-link
                                to="/trawltruck/ho/order/create"
                                class="btn btn-green btn-filter px-4 w-100"
                            >
                                <i class="fa fa-plus me-1"></i>
                                Buat Project On Call
                            </router-link>
                        </div>
                        <div class="col-lg-3 my-2">
                            <button
                                class="btn btn-outline-black btn-filter px-4 w-100"
                                @click="isFilter = !isFilter"
                            >
                                <img
                                    src="../../assets/dashboard/filter.png"
                                    class="me-1"
                                    width="18"
                                    alt=""
                                />
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div
                        class="d-flex align-items-center justify-content-lg-end justify-content-center"
                    >
                        <div class="me-2">Rows per page</div>
                        <div>
                            <select
                                class="form-control form-select w-select"
                                v-model="perPage"
                                @change="(currentPage = 1), getLists()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box mt-3" v-if="isFilter">
                <h5 class="fw-bold">Filter Data</h5>
                <div class="row">
                    <div class="col-lg-3 my-2">
                        <label class="fw-bold mb-2">Tanggal Order</label>
                        <DateRangePicker
                            placeholder-text="Pilih tanggal order"
                            v-model="orderDate"
                        />
                    </div>
                    <div class="col-lg-3 my-2">
                        <label class="fw-bold mb-2"
                            >Tanggal Jadwal Pesanan</label
                        >
                        <DatePicker
                            v-model="orderScheduleDate"
                            placeholder-text="Pilih tanggal jadwal"
                        />
                    </div>
                    <div class="col-lg-3 my-2">
                        <label class="fw-bold mb-2">Status Resi</label>
                        <multiselect
                            v-model="receiptStatus"
                            :options="receiptStatuses"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih jenis status resi"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-3 my-2">
                        <label class="fw-bold mb-2">Jenis Armada</label>
                        <multiselect
                            v-model="fleetType"
                            :options="fleetTypes"
                            :show-labels="false"
                            label="name"
                            track-by="code"
                            placeholder="Pilih jenis armada"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-3">
                        <div class="row">
                            <div class="col-lg-6 my-2">
                                <button
                                    class="btn btn-outline-black w-100"
                                    :disabled="
                                        !orderDate.from &&
                                        !orderDate.to &&
                                        !orderScheduleDate &&
                                        !receiptStatus &&
                                        !fleetType
                                    "
                                    @click="
                                        (currentPage = 1),
                                            (orderDate.from = null),
                                            (orderDate.to = null),
                                            (orderScheduleDate = null),
                                            (receiptStatus = null),
                                            (fleetType = null),
                                            getLists()
                                    "
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-lg-6 my-2">
                                <button
                                    class="btn btn-green w-100"
                                    :disabled="
                                        !orderDate.from &&
                                        !orderDate.to &&
                                        !orderScheduleDate &&
                                        !receiptStatus &&
                                        !fleetType
                                    "
                                    @click="(currentPage = 1), getLists()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoading">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table
                    class="table table-bordered mt-4"
                    v-if="lists?.list_data?.length > 0"
                >
                    <thead class="table-light">
                        <tr>
                            <th class="text-center">No</th>
                            <th class="text-center">Tanggal Order</th>
                            <th class="text-center">Status Resi</th>
                            <th class="text-center">Nama Customer</th>
                            <th class="text-center">Nama Pengirim</th>
                            <th class="text-center">Armada & Rute</th>
                            <th class="text-center">Jadwal Pesanan</th>
                            <th class="text-center">Pembayaran</th>
                            <th class="text-center">Detail Mitra</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(list, index) in lists?.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <p>{{ list.order_date }}</p>
                                    <div
                                        class="rounded-pill px-3 py-1"
                                        :class="{
                                            'red-badge':
                                                list.create_via == 'app',
                                            'green-badge':
                                                list.create_via == 'onc',
                                        }"
                                    >
                                        {{
                                            list.create_via == "app"
                                                ? "Troben App"
                                                : "Project On Call"
                                        }}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <div
                                        class="rounded-pill px-3 py-1"
                                        :class="{
                                            'peach-badge':
                                                list.status === 'pending' ||
                                                list.status === 'waiting',
                                            'blue-badge':
                                                list.status === 'pickup' ||
                                                list.status === 'loading' ||
                                                list.status === 'ontheway' ||
                                                list.status === 'unloading',
                                            'pink-badge':
                                                list.status === 'cancel',
                                            'sage-badge':
                                                list.status === 'done',
                                        }"
                                    >
                                        {{ list.status_label }}
                                    </div>
                                    <router-link
                                        class="link mt-2"
                                        :to="
                                            '/trawltruck/ho/order-detail/' +
                                            list.id
                                        "
                                        >{{ list.code }}</router-link
                                    >
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">
                                        {{ list.customer }}
                                    </p>
                                    <p class="mb-0 timeline-title">
                                        {{ list.phone }}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">
                                        {{ list.sender_name }}
                                    </p>
                                    <p class="mb-0 timeline-title">
                                        {{ list.sender_phone }}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <p class="mb-0 text-primary">
                                        {{ list.fleet_type }}
                                    </p>
                                    <p class="mb-0 mt-2">{{ list.route }}</p>
                                </div>
                            </td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <div
                                        class="blue-badge rounded-pill px-3 py-1"
                                        :class="{
                                            'blue-badge':
                                                list.order_type === 'schedule',
                                            'sage-badge':
                                                list.order_type === 'quick',
                                        }"
                                    >
                                        {{
                                            list.order_type === "schedule"
                                                ? "Terjadwal"
                                                : "Quick"
                                        }}
                                    </div>
                                    <p class="mb-0 mt-2">
                                        {{
                                            list.beginpickup_at
                                                ? list.beginpickup_at
                                                : "-"
                                        }}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <div
                                        class="rounded-pill px-3 py-1"
                                        :class="{
                                            'peach-badge':
                                                list.payment_status !== 'paid',
                                            'sage-badge':
                                                list.payment_status === 'paid',
                                        }"
                                    >
                                        {{
                                            list.payment_status !== "paid"
                                                ? "Unpaid"
                                                : list.payment_status ===
                                                      "paid" &&
                                                  list.payment_method === "va"
                                                ? "Paid by VA"
                                                : "Paid By Cash"
                                        }}
                                    </div>
                                    <p class="mb-0 mt-2">
                                        {{ currency(list.total_amount) }}
                                        <span
                                            class="text-green cursor-pointer"
                                            v-if="
                                                list.can_download_invoice ||
                                                list.payment_status !== 'paid'
                                            "
                                            @click="
                                                list.can_download_invoice
                                                    ? downloadFaktur(
                                                          list.id,
                                                          index
                                                      )
                                                    : list.payment_status !==
                                                      'paid'
                                                    ? showModalVA(list.id)
                                                    : ''
                                            "
                                        >
                                            |
                                            <u>{{
                                                list.can_download_invoice
                                                    ? "View Faktur"
                                                    : list.payment_status !==
                                                      "paid"
                                                    ? "View VA"
                                                    : ""
                                            }}</u>
                                        </span>
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <button
                                        class="btn btn-red d-flex align-items-center"
                                        v-if="
                                            list.payment_status === 'paid' &&
                                            list.driver &&
                                            (list.status === 'pending' ||
                                                list.status === 'waiting')
                                        "
                                        @click="getEditMitra(list.id)"
                                    >
                                        <img
                                            src="../../assets/pencil.svg"
                                            alt="pencil"
                                            class="me-2"
                                        />
                                        Ubah Mitra
                                    </button>
                                    <div v-else-if="list.driver">
                                        <p class="fw-bold mb-0">
                                            {{ list.driver.name }}
                                        </p>
                                        <p class="mb-0 timeline-title">
                                            {{ list.driver.phone }}
                                        </p>
                                    </div>
                                    <p class="mb-0" v-else-if="!list.driver">
                                        -
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    class="d-flex flex-column justify-content-center align-items-center mt-5"
                    v-else
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <p
                    class="cursor-pointer"
                    :class="{ 'pagination-disabled': currentPage === 1 }"
                    @click="
                        currentPage > 1 && handlePageChange(currentPage - 1)
                    "
                >
                    <i class="fa fa-angle-left me-2"></i>Previous
                </p>
                <p class="mx-4">{{ currentPage }} of {{ totalPages }}</p>
                <p
                    class="cursor-pointer"
                    :class="{
                        'pagination-disabled': currentPage === totalPages,
                    }"
                    @click="
                        currentPage < totalPages &&
                            handlePageChange(currentPage + 1)
                    "
                >
                    Next <i class="fa fa-angle-right ms-2"></i>
                </p>
            </div>
        </div>
        <b-modal v-model="modalVA" size="lg" hide-footer hide-header>
            <div class="p-3">
                <h4 class="fw-bold d-flex align-items-center mb-0">
                    <i class="fa fa-file me-2 size-18"></i>
                    Lakukan Pembayaran
                </h4>
                <div class="box no-shadow border-gray mt-4 p-3">
                    <h5 class="mb-3 fw-bold">Rincian Pembayaran</h5>
                    <div class="d-flex align-items-center">
                        <div>
                            <h6 class="mb-0 size-16">Total Pembayaran</h6>
                        </div>
                        <div class="ms-auto">
                            <div class="text-green fw-bold">
                                <span class="size-18" ref="paymentCopy">
                                    {{
                                        currency(
                                            detail.payment.total_payment
                                                ? detail.payment.total_payment
                                                : 0
                                        )
                                    }}
                                </span>
                                <a
                                    href="javascript:void(0)"
                                    @click="paymentCopy()"
                                >
                                    <img
                                        src="../../assets/clone.png"
                                        width="20"
                                        class="ms-1"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <div>
                            <h6 class="mb-0 size-16">Jatuh Tempo</h6>
                        </div>
                        <div class="ms-auto">
                            <template
                                v-if="
                                    moment(detail.payment.expired_at).format(
                                        'YYYY-MM-DD hh:mm:ss'
                                    ) < moment().format('YYYY-MM-DD hh:mm:ss')
                                "
                            >
                                <div class="size-18 text-primary fw-bold">
                                    {{ expired }}
                                </div>
                            </template>
                            <template v-else>
                                <div
                                    id="countdown"
                                    class="size-18 text-primary fw-bold"
                                ></div>
                            </template>
                            <div class="text-yellow">
                                Jatuh tempo
                                {{
                                    moment(detail.payment.expired_at).format(
                                        "DD MMMM YYYY hh:mm:ss"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center">
                        <div>
                            <h6 class="mb-0 size-16">Metode Pembayaran</h6>
                        </div>
                        <div class="ms-auto">
                            <div class="size-18 fw-bold capitalize">
                                <template v-if="detail.type_payment == 'va'">
                                    Virtual Account
                                </template>
                                <template v-else>
                                    {{
                                        detail.type_payment
                                            ? detail.type_payment
                                            : "-"
                                    }}
                                </template>
                                -
                                {{
                                    detail.payment.channel_name
                                        ? detail.payment.channel_name
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box no-shadow border-gray mt-3 p-3">
                    <h5 class="mb-3 fw-bold">Nomor Virtual Account</h5>
                    <div class="box-va">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    :src="detail.payment.icon"
                                    width="50"
                                    alt=""
                                />
                            </div>
                            <div class="fw-semibold size-16" ref="VACopy">
                                {{ detail.payment.payment_ref_content }}
                            </div>
                            <div class="ms-auto">
                                <a
                                    href="javascript:void(0)"
                                    class="text-green size-16"
                                    @click="VACopy()"
                                >
                                    Salin
                                    <img
                                        src="../../assets/clone.png"
                                        width="20"
                                        class="ms-1"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 text-green size-13">
                        Bayar pesanan ke Virtual Account di atas sebelum membuat
                        pesanan kembali dengan Virtual Account agar nomor tetap
                        sama.
                    </div>
                    <div class="mt-2">
                        <button
                            class="btn btn-green w-100"
                            @click="(modalVaCreate = true), (modalVA = false)"
                        >
                            Buat VA Baru
                        </button>
                    </div>
                </div>
                <div
                    class="box no-shadow bc-light-yellow mt-3 p-3 border-small"
                >
                    <img
                        src="../../assets/info-new.png"
                        width="16"
                        class="me-2"
                        alt=""
                    />
                    Proses verifikasi 10 menit setelah pembayaran berhasil
                </div>
                <div class="box no-shadow border-gray mt-3 p-3">
                    <h5 class="mb-3 fw-bold">Petunjuk Pembayaran</h5>
                    <div class="accordion" role="tablist">
                        <b-card
                            no-body
                            v-for="(step, index) in payment_procedure"
                            :key="index"
                        >
                            <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                            >
                                <div
                                    class="d-flex align-items-center"
                                    block
                                    v-b-toggle="'accordion-' + index"
                                    variant="info"
                                >
                                    <div class="size-14 fw-bold">
                                        {{ step.payment_channel }}
                                    </div>
                                    <div class="ms-auto">
                                        <i class="fa fa-angle-down size-18"></i>
                                    </div>
                                </div>
                            </b-card-header>
                            <b-collapse
                                :id="'accordion-' + index"
                                accordion="my-accordion"
                                role="tabpanel"
                            >
                                <b-card-body>
                                    <div v-html="step.payment_procedure"></div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="modalVaCreate" size="lg" hide-footer hide-header>
            <div class="p-3">
                <a
                    href="javascript:void(0)"
                    class="text-dark"
                    @click="(modalVaCreate = false), (modalVA = true)"
                >
                    <h4 class="fw-bold d-flex align-items-center mb-0">
                        <i class="fa fa-angle-left me-2 size-18"></i>
                        Buat VA Baru
                    </h4>
                </a>
                <div class="box no-shadow border-gray mt-3 p-3">
                    <h5 class="mb-3 fw-bold">Bank pembayaran sebelumnya</h5>
                    <div class="box-va">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    :src="detail.payment.icon"
                                    width="50"
                                    alt=""
                                />
                            </div>
                            <div class="fw-semibold size-16" ref="VACopy">
                                {{ detail.payment.payment_ref_content }}
                            </div>
                        </div>
                    </div>
                    <h5 class="mb-0 fw-bold mt-3">Pilih bank pembayaran</h5>
                    <div class="text-gray">
                        Pilih bank untuk membuat Virtual Account baru
                    </div>
                    <div class="mt-2">
                        <multiselect
                            v-model="value"
                            placeholder="Pilih Bank"
                            label="full_name_label"
                            track-by="id"
                            :options="options"
                        >
                            <template #singleLabel="props">
                                <div class="d-flex align-items-center">
                                    <img :src="props.option.icon" width="40" />
                                    <div class="ms-2">
                                        {{ props.option.full_name_label }}
                                    </div>
                                </div>
                            </template>
                            <template #option="props">
                                <div class="d-flex align-items-center">
                                    <img :src="props.option.icon" width="40" />
                                    <div class="ms-2">
                                        {{ props.option.full_name_label }}
                                    </div>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <div class="mt-3">
                    <button
                        class="btn btn-green w-100"
                        :disabled="value == null || is_create_va"
                        @click="createVa()"
                    >
                        <span
                            v-if="is_create_va"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        >
                        </span>
                        Simpan
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="modalFleet" size="md" hide-footer hide-header>
            <div class="p-3">
                <h4 class="fw-bold d-flex align-items-center mb-0">
                    Mitra Penjemputan
                </h4>
                <div class="mt-3">
                    <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                        <tr>
                            <td class="ps-0 w-120">Mitra Pickup</td>
                            <td class="w-30">:</td>
                            <td>
                                <multiselect
                                    v-model="assign_order"
                                    :options="fleetTypes"
                                    label="name"
                                    track-by="id"
                                    placeholder="Pilih mitra"
                                    class="multi-form-custom"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-120">Komisi Mitra</td>
                            <td class="w-30">:</td>
                            <td>
                                <money
                                    v-model="commission_partner"
                                    v-bind="format_number"
                                    class="form-control w-koli"
                                    placeholder="Komisi Mitra"
                                >
                                </money>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-120">Helper</td>
                            <td class="w-30">:</td>
                            <td>
                                <money
                                    v-model="additional_price"
                                    v-bind="format_number"
                                    class="form-control w-koli"
                                    placeholder="Helper"
                                >
                                </money>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="mt-2">
                    <button
                        class="btn btn-green w-100"
                        :disabled="assign_order == null || is_store_mitra"
                        @click="storeMitra()"
                    >
                        <span
                            class="spinner-border spinner-border-sm me-2"
                            v-if="is_store_mitra"
                        ></span>
                        Simpan Perubahan
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Api from "../../api/Api";
import DateRangePicker from "../../components/DateRangePicker.vue";
import DatePicker from "../../components/DatePicker.vue";
import Multiselect from "vue-multiselect";
import ListLoader from "../../components/ListLoader.vue";
import { Money } from "v-money";
import moment from "moment";
export default {
    components: {
        DateRangePicker,
        DatePicker,
        Multiselect,
        ListLoader,
        Money,
    },
    data() {
        return {
            moment: moment,
            search: "",
            perPage: 10,
            isFilter: false,
            receiptStatuses: [
                {
                    id: "cancel",
                    name: "Dibatalkan",
                },
                {
                    id: "pending",
                    name: "Menunggu Pembayaran / Mencari Driver",
                },
                {
                    id: "waiting",
                    name: "Driver Ditemukan",
                },
                {
                    id: "pickup",
                    name: "Driver menuju lokasi penjemput",
                },
                {
                    id: "loading",
                    name: "Loading Barang",
                },
                {
                    id: "ontheway",
                    name: "Driver menuju lokasi penerima",
                },
                {
                    id: "unloading",
                    name: "Driver sampai di lokasi penerima",
                },
                {
                    id: "done",
                    name: "Selesai",
                },
            ],
            fleetTypes: [],
            orderDate: {
                from: null,
                to: null,
            },
            orderScheduleDate: null,
            receiptStatus: null,
            fleetType: null,
            lists: null,
            isLoading: false,
            currentPage: 1,
            totalPages: 1,
            modalVA: false,
            modal_id: null,
            detail: {
                payment: {},
            },
            payment_procedure: [],
            modalVaCreate: false,
            value: null,
            options: [],
            is_create_va: false,
            modalFleet: false,
            assign_order: null,
            format_number: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp",
                suffix: "",
                precision: 0,
                masked: false,
                maxLength: 13,
                minimumValue: 0,
            },
            commission_partner: 0,
            additional_price: 0,
            is_store_mitra: false,
        };
    },
    created() {
        this.getLists();
        this.getFleets();
        this.getBank();
    },
    methods: {
        getFleets() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/trawltruck/fleet`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.fleetTypes = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.getLists();
        },
        getLists() {
            this.isLoading = true;
            const payload = {
                search: this.search,
                limit: this.perPage,
                page: this.currentPage - 1,
                status: this.receiptStatus?.id,
                fleet: this.fleetType?.code,
            };

            if (this.orderDate?.from && this.orderDate?.to) {
                payload.start_date = moment(this.orderDate.from).format(
                    "YYYY-MM-DD"
                );
                payload.end_date = moment(this.orderDate.to).format(
                    "YYYY-MM-DD"
                );
            }
            if (this.orderScheduleDate) {
                payload.order_date = moment(this.orderScheduleDate).format(
                    "YYYY-MM-DD"
                );
            }

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPages = res.data.data.total_page || 1;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.isLoading = false;
                });
        },
        showModalVA(id) {
            this.modalVA = true;
            this.modal_id = id;
            this.getDetail();
        },
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/detail?id=${this.modal_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                    if (
                        moment(data.payment.expired_at).format(
                            "YYYY-MM-DD hh:mm:ss"
                        ) < moment().format("YYYY-MM-DD hh:mm:ss")
                    ) {
                        this.expired = "Expired";
                    } else {
                        const element = document.getElementById("countdown");
                        const exp = moment(data.payment.expired_at);

                        setInterval(function () {
                            let now = moment().format();
                            let diffDuration = moment.duration(exp.diff(now));
                            element.innerHTML =
                                diffDuration.days() +
                                " " +
                                "hari" +
                                " " +
                                diffDuration.hours() +
                                " " +
                                "jam" +
                                " " +
                                diffDuration.minutes() +
                                " " +
                                "menit" +
                                " " +
                                diffDuration.seconds() +
                                " " +
                                "detik";
                        }, 1000);
                    }
                    this.getPaymentProcedure();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getPaymentProcedure() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/general/payment/procedure?bank_id=${this.detail.payment.gateway_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.payment_procedure = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        paymentCopy() {
            const text = this.$refs.paymentCopy.innerText;
            navigator.clipboard.writeText(text);
            this.$notify({
                group: "foo",
                type: "success",
                text: "Total pembayaran berhasil dicopy",
            });
        },
        VACopy() {
            const text = this.$refs.VACopy.innerText;
            navigator.clipboard.writeText(text);
            this.$notify({
                group: "foo",
                type: "success",
                text: "Nomor virtual account berhasil dicopy",
            });
        },
        downloadFaktur(id) {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/invoice/truck/download?order_id=${id}`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    window.open(res.data.data.file_url, "_blank");
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        getBank() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/oncall/payment`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.options = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        createVa() {
            this.is_create_va = true;
            var data = {
                order_id: this.modal_id,
                payment_channel: this.value?.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/regenerate_va`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_create_va = false;
                    this.getDetail();
                    this.modalVaCreate = false;
                    this.modalVA = true;
                    this.value = null;
                })
                .catch((err) => {
                    this.is_create_va = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        getEditMitra(id) {
            this.modalFleet = true;
            this.modal_id = id;
            this.getDetailAfterEdit();
            this.assign_order = null;
        },
        getDetailAfterEdit() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/detail?id=${this.modal_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.type_fleet = res.data.data.type_fleet;
                    this.commission_partner = res.data.data.income_partner;
                    this.additional_price = res.data.data.additional_price;
                    this.getFleets();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        storeMitra() {
            this.is_store_mitra = true;
            var data = {
                order_id: this.modal_id,
                assign_order: this.assign_order?.id,
                commission_partner: this.commission_partner,
                additional_price: this.additional_price,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/update_assign`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.modalFleet = false;
                    this.is_store_mitra = false;
                    this.getList();
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Mitra berhasil diupdate",
                    });
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                    this.is_store_mitra = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.payment-li {
    line-height: 25px;
}
.card-header {
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid #f4f6f7;
    padding: 5px 0px !important;
}
.card {
    border: unset;
}
.card-body {
    padding: 0px;
    padding-top: 6px;
    border-bottom: 1px solid #f4f6f7;
}
.box-va {
    border: 1px solid #d2d2d5;
    border-radius: 10px;
    padding: 8px 20px;
    background: #fbfbfb;
    color: #000;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
    vertical-align: middle;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.red-badge {
    background-color: #e60013;
    color: #f6faff;
}
.green-badge {
    background-color: #3d8824;
    color: #f6faff;
}
.peach-badge {
    background-color: #feead4;
    color: #fb9727;
}
.blue-badge {
    background-color: #daecf6;
    color: #3c87b1;
}
.pink-badge {
    background-color: #faccd0;
    color: #c00010;
}
.sage-badge {
    background-color: #d8e7d3;
    color: #33711e;
}
.link {
    color: #3c87b1;
    border-bottom: 1px solid #3c87b1;
}
.pagination-disabled {
    color: #d2d2d5;
    cursor: no-drop !important;
}
</style>
